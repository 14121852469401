import { render, staticRenderFns } from "./TermsOfUse.vue?vue&type=template&id=9e12cfae&scoped=true"
import script from "./TermsOfUse.vue?vue&type=script&lang=js"
export * from "./TermsOfUse.vue?vue&type=script&lang=js"
import style0 from "./TermsOfUse.vue?vue&type=style&index=0&id=9e12cfae&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e12cfae",
  null
  
)

export default component.exports