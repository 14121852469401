<template>
  <div>
    <div class="terms-container">
      <div class="terms-header">
        <img
          :alt="$config.client.name"
          :src="logoPath"
          class="client-logo"
        />
      </div>
      <div v-if="!error">
        <b-overlay
          :show="loading"
          :variant="'white'"
          rounded="lg"
          opacity="0.9"
        >
          <template #overlay>
            <div class="d-flex align-items-center">
              <b-spinner variant="secondary"></b-spinner>
            </div>
          </template>
          <div class="terms-title">
            {{ terms ? terms.title : '' }}
          </div>
          <div class="terms-content">
            <div class="terms-date">
              {{ terms ? new Date(terms.issue_date.slice(0, 10)).toLocaleString('fr-FR', {
                year: "numeric",
                month: "long",
                day: "numeric"
              }) : '' }}<br>
            </div>
            <div v-html="terms ? terms.body : ''"></div>
            <a v-if="terms && terms.file" :href="terms.file" target="_blank">
              {{ $t('termsOfUse.download') }}
            </a>
          </div>
          <div class="terms-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-test="termsOfUse-accept"
              @click.prevent="acceptTermsOfUse"
            >
              {{ $t('words.accept') }}
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-test="termsOfUse-signIn"
              @click="$router.push({ name: 'SignIn' })"
            >
              {{ $t('words.decline') }}
            </button>
          </div>
        </b-overlay>
      </div>
      <div v-else class="terms-container">
        <b-container class="msg">
          <p>
            {{ $t('termsOfUse.error') }}
          </p>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'TermsOfUse',

  data() {
    return {
      loading: false
    }
  },

  computed: {
    ...mapState('terms-of-use', [
      'error',
      'terms'
    ]),

    logoPath() {
      return require(process.env.VUE_APP_LOGO);
    }
  },

  created() {
    this.loading = true;
    this.GET_TERMS_OF_USE().then(() => {
      this.loading = false;
    });
  },

  methods: {
    ...mapMutations('terms-of-use', [
      'SET_ERROR'
    ]),
    ...mapActions('terms-of-use', [
      'GET_TERMS_OF_USE',
      'AGREE_TO_TERMS_OF_USE'
    ]),

    acceptTermsOfUse() {
      this.AGREE_TO_TERMS_OF_USE({
        has_agreed: true,
        authorizedRedirections: this.$config.authorizedRedirections
      });
    },

    retry() {
      this.SET_ERROR({
        message: null
      });
    }
  }
}
</script>

<style lang="less" scoped>
.terms-container {
  margin: auto;
  width: 600px;
  height: fit-content;
  max-height: 80vh;

  .terms-header {
    margin: 0 1rem 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img.client-logo {
      max-width: 560px;
      max-height: 150px;
      object-fit: contain;
    }
  }

  .terms-title {
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
  }
  .terms-content {
    text-align: justify;
    margin: 1em 20px;
    min-height: 10rem;
    .terms-date {
      text-align: right;
      margin-bottom: 1rem;
    }
  }
  .terms-footer {
    height: 7rem;
    margin: 2em 40px;
    button.btn {
      float: right;
      position: relative;
      margin-left: 7px;
      margin-right: 0;
    }

    button.btn-primary {
      border: 2px solid #9BD0FF;
      border-radius: 8px;
    }
    button.btn-outline-secondary {
      background-color: #F7F8FA;
      border: 2px solid #A9B2B9;
      border-radius: 8px;
      color: #2F3234;
    }
    button.btn-outline-secondary:hover {
      color: white;
      background-color: #4b4b4b;
    }
  }
}
</style>